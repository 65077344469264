import React, { Component } from "react"
// import { Link } from "gatsby"
import Airtable from "airtable"
import classNames from "classnames"
import BodyClassName from "react-body-classname"
import CountUp from "react-countup"
import ScrollTrigger from "react-scroll-trigger"
import Scroll from "react-scroll"
import {
  Buttons,
  Button,
  Header,
  Footer,
  Stars,
  Input
} from "../components/common"
import "../less/app.less"

Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: "keyJaT94OmfBVzZoK"
})
var base = Airtable.base("appvLPIhobpNYMVLr")

const easingFn = function(t, b, c, d) {
  if ((t /= d / 2) < 1) return (c / 2) * t * t * t * t + b
  return (-c / 2) * ((t -= 2) * t * t * t - 2) + b
}
const formatDollar = number => `$${new Intl.NumberFormat().format(number)}`

const Grade = ({ total }) => {
  let copy
  if (total > 1000000) {
    copy = "Amazing, you’ve made it. "
  } else if (total > 300000) {
    copy = "Good job, you’re building something great. "
  } else if (total > 100000) {
    copy = "Not bad, you’re well on your way to building something great. "
  } else if (total < 100000) {
    copy = ""
  }
  return <span>{copy}</span>
}

class ScorePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      numberVisible: false,
      score: 0,
      total: 0,
      email: "",
      emailDisabled: false,
      values: {
        brokerage_asset: 0,
        cash_asset: 0,
        checking_asset: 0,
        credit_debt: 0,
        crypto_asset: 0,
        equity_asset: 0,
        home_asset: 0,
        home_debt: 0,
        hsa_asset: 0,
        medical_debt: 0,
        metals_asset: 0,
        other_asset: 0,
        other_debt: 0,
        property_asset: 0,
        property_debt: 0,
        retire_asset: 0,
        roth_asset: 0,
        savings_asset: 0,
        student_debt: 0
      }
    }
    this.handleEmail = this.handleEmail.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onNumberVisible = this.onNumberVisible.bind(this)
    this.onNumberHidden = this.onNumberHidden.bind(this)
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      const localValues = {
        brokerage_asset:
          JSON.parse(localStorage.getItem("brokerage_asset")) || 0,
        cash_asset: JSON.parse(localStorage.getItem("cash_asset")) || 0,
        checking_asset: JSON.parse(localStorage.getItem("checking_asset")) || 0,
        credit_debt: JSON.parse(localStorage.getItem("credit_debt")) || 0,
        crypto_asset: JSON.parse(localStorage.getItem("crypto_asset")) || 0,
        equity_asset: JSON.parse(localStorage.getItem("equity_asset")) || 0,
        home_asset: JSON.parse(localStorage.getItem("home_asset")) || 0,
        home_debt: JSON.parse(localStorage.getItem("home_debt")) || 0,
        hsa_asset: JSON.parse(localStorage.getItem("hsa_asset")) || 0,
        medical_debt: JSON.parse(localStorage.getItem("medical_debt")) || 0,
        metals_asset: JSON.parse(localStorage.getItem("metals_asset")) || 0,
        other_asset: JSON.parse(localStorage.getItem("other_asset")) || 0,
        other_debt: JSON.parse(localStorage.getItem("other_debt")) || 0,
        property_asset: JSON.parse(localStorage.getItem("property_asset")) || 0,
        property_debt: JSON.parse(localStorage.getItem("property_debt")) || 0,
        retire_asset: JSON.parse(localStorage.getItem("retire_asset")) || 0,
        roth_asset: JSON.parse(localStorage.getItem("roth_asset")) || 0,
        savings_asset: JSON.parse(localStorage.getItem("savings_asset")) || 0,
        student_debt: JSON.parse(localStorage.getItem("student_debt")) || 0
      }
      this.setState({
        values: localValues,
        score: JSON.parse(localStorage.getItem("score")) || 0,
        total: JSON.parse(localStorage.getItem("total")) || 0,
        email: localStorage.getItem("email") || ""
      })
    }
  }
  handleEmail(e) {
    if (typeof window !== "undefined") {
      localStorage.setItem("email", e.target.value)
    }
    this.setState({ email: e.target.value })
  }
  async handleSubmit(e) {
    e.preventDefault()

    if (typeof window !== "undefined") {
      if (localStorage.getItem("record")) {
        base("newsletter").update(
          [
            {
              id: localStorage.getItem("record"),
              fields: {
                email: this.state.email,
                total: this.state.total,
                score: this.state.score,
                ...this.state.values
              }
            }
          ],
          function(err, records) {
            if (err) {
              console.error(err)
              return
            }
            records.forEach(function(record) {
              // console.log(record.get("email"))
            })
          }
        )
      } else {
        base("newsletter").create(
          [
            {
              fields: {
                email: this.state.email,
                total: this.state.total,
                score: this.state.score,
                ...this.state.values
              }
            }
          ],
          function(err, records) {
            if (err) {
              console.error(err)
              return
            }
            records.forEach(function(record) {
              localStorage.setItem("record", record.getId())
            })
          }
        )
      }
    }

    this.setState({ emailDisabled: true })

    const to_send = {
      email: this.state.email,
      score: this.state.score,
      total: formatDollar(this.state.total)
    }

    try {
      const response = await fetch("/.netlify/functions/email", {
        method: "POST",
        body: JSON.stringify(to_send)
      })
      if (!response.ok) {
        //Do something when request fails
        return
      }
      //Do something when request is successful
    } catch (e) {
      console.log(e)
    }
  }
  onNumberVisible() {
    const saveScore = this.state.score
    this.setState({ numberVisible: true })
    this.setState({ score: 0 })
    this.setState({ score: saveScore })
  }
  onNumberHidden() {
    this.setState({ numberVisible: false })
  }
  render() {
    const { score, total, numberVisible, email, emailDisabled } = this.state
    return (
      <BodyClassName className="score">
        <div className="app">
          <div className="intro">
            <div className="intro-background" />
            <div className="intro-foreground content">
              <div className="intro-title">
                <div
                  className="counter"
                  title={`$${new Intl.NumberFormat().format(total)}`}
                >
                  <CountUp
                    end={total}
                    prefix={"$"}
                    separator={","}
                    duration={1.5}
                    delay={0.5}
                    easingFn={easingFn}
                  />
                </div>
              </div>
              <div className="intro-description">
                That’s your total net worth. <Grade total={total} /> Tracking
                your net worth is essential for managing your financial health.
                At Gild, we help people unlock their full potential and achieve
                peak financial fitness. Want to see your financial fitness
                score?
              </div>
              <div className="intro-action">
                <Buttons>
                  <Scroll.Link
                    to="featurette"
                    className="button dark"
                    smooth={true}
                    duration={500}
                    offset={0}
                  >
                    See score.
                  </Scroll.Link>
                </Buttons>
              </div>
            </div>
          </div>
          <Header />
          <main className="main">
            <Scroll.Element className="featurette" name="featurette">
              <div className="duet reverse content">
                <div className="duet-story">
                  <div className="duet-subtitle">
                    <h6>Wait, there’s more.</h6>
                  </div>
                  <div className="underline" />
                  <h2>This is your financial fitness score.</h2>
                  <p>
                    After crunching the numbers, our score estimates how well
                    you’re investing your capital and how much your wealth will
                    grow in the future. Gild offers tools and training to
                    strengthen your financial muscles and discover how to earn
                    better returns and improve your score.
                  </p>
                  <Scroll.Link
                    to="billboard"
                    className="link"
                    smooth={true}
                    duration={500}
                  >
                    Learn more.
                  </Scroll.Link>
                </div>
                <div className="duet-media">
                  <div className="scoreboard">
                    <div className="scoreboard-inner">
                      <ScrollTrigger
                        onEnter={this.onNumberVisible}
                        onExit={this.onNumberHidden}
                        className={classNames({
                          "scoreboard-number": true,
                          visible: numberVisible
                        })}
                      >
                        <CountUp
                          end={score}
                          duration={1.5}
                          delay={0}
                          easingFn={easingFn}
                          prefix={""}
                          decimals={1}
                        />
                      </ScrollTrigger>
                      <div className="scoreboard-stars">
                        <Stars score={score} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Scroll.Element>
            <Scroll.Element className="billboard" name="billboard">
              <div className="content">
                <h1>Sign up to learn more.</h1>
                <p>
                  Meet Gild, an exclusive club dedicated to your financial
                  fitness. Think of it as a premium gym membership for your
                  finances. Sign up to know when we finish a new feature and
                  we’ll send you tips to become the most financially fit version
                  of yourself.
                </p>
                <form className="intake" onSubmit={this.handleSubmit}>
                  <div className="intake-input">
                    <Input
                      value={email}
                      type="email"
                      onChange={this.handleEmail}
                      disabled={emailDisabled}
                      required
                    >
                      Email address
                    </Input>
                  </div>
                  <div className="intake-action">
                    <Button
                      className="primary"
                      disabled={emailDisabled}
                      type="submit"
                    >
                      {emailDisabled ? "Sent!" : "Sign up"}
                    </Button>
                  </div>
                </form>
              </div>
            </Scroll.Element>
            <Footer />
          </main>
        </div>
      </BodyClassName>
    )
  }
}

export default ScorePage
